<template>
  <div
    class="inline-flex items-center rounded-full py-1 pl-2 pr-[12px]"
    :class="[
      { 'bg-gpx_red-300': color === 'red' },
      { 'bg-gpx_yellow-300': color === 'yellow' },
      { 'bg-gpx_blue-300': color === 'blue' },
      { 'bg-gpx_green-300': color === 'green' },
      { 'bg-gpx_porto-900': color === 'porto' },
      { 'bg-gpx_gray-800': color === 'gray' },
    ]"
  >
    <div
      class="h-2 w-2 rounded-full"
      :class="[
        { 'bg-gpx_red-200': color === 'red' },
        { 'bg-gpx_yellow-200': color === 'yellow' },
        { 'bg-gpx_blue-200': color === 'blue' },
        { 'bg-gpx_green-200': color === 'green' },
        { 'bg-gpx_porto-400': color === 'porto' },
        { 'bg-gpx_gray-100': color === 'gray' },
      ]"
    ></div>
    <div
      class="ml-1.5 text-xs font-medium capitalize"
      :class="[
        { 'text-gpx_red-100': color === 'red' },
        { 'text-gpx_yellow-100': color === 'yellow' },
        { 'text-gpx_blue-100': color === 'blue' },
        { 'text-gpx_green-100': color === 'green' },
        { 'text-gpx_porto-100': color === 'porto' },
        { 'text-lms_black-500': color === 'gray' },
      ]"
    >
      {{ statusText }}{{ suffix }}
    </div>
  </div>
</template>

<script>
import startCase from 'lodash/startCase';
import { statusColorMap } from '@/config/constants';

export default {
  props: {
    status: {
      type: String,
    },
    suffix: {
      type: String,
    },
  },

  computed: {
    color() {
      return statusColorMap[this.status];
    },
    statusText() {
      const statusMap = {
        'pending-approval': 'pending-approval',
        'pending-cancellation': 'Pending Cancellation',
        'pending-shipment': 'Pending Shipment',
        archived: 'Cancelled',
      };

      return statusMap[this.status] || startCase(this.status);
    },
  },

  methods: {
    startCase,
  },
};
</script>

<style></style>
