export const TABLE_RESULTS_PER_PAGE = [12, 24, 36, 48];
export const LIST_RESULTS_PER_PAGE = [25, 50, 100, 200];

export const MESSAGE401 = 'Your session has been expired, please log in again';
export const MESSAGE403 = 'Access denied';

export const COMMON_SAVE_SUCCESS = 'Saved successfully!';
export const COMMON_SAVE_ERROR = 'Cannot save, please try again';

export const COMMON_DELETE_SUCCESS = 'Successfully deleted!';
export const COMMON_DELETE_ERROR = 'Cannot delete, please try again';

export const COMMON_REMOVE_SUCCESS = 'Successfully removed!';
export const COMMON_REMOVE_ERROR = 'Cannot remove, please try again';

export const COMMON_UPDATE_SUCCESS = 'Updated!';
export const COMMON_UPDATE_ERROR = 'Cannot update, please try again';

export const COMMON_ARCHIVE_SUCCESS = 'Archived successfully!';
export const COMMON_ARCHIVE_ERROR = 'Cannot archive, try to refresh the page';
export const COMMON_UNARCHIVE_SUCCESS = 'Unarchived successfully!';
export const COMMON_UNARCHIVE_ERROR =
  'Cannot unarchive, try to refresh the page';

export const PASSWORD_UPDATE_SUCCESS = 'Password has been updated!';
export const SHIPMENT_END_SUCCESS = 'Successfully ended!';

export const TRACK_MESSAGE404 = 'This track Nº does not exist';
export const REPORT_MESSAGE404 = 'This report does not exist';

export const LEFT_PAGE = 'LEFT';
export const RIGHT_PAGE = 'RIGHT';

export const RANGEPICKER_INTERVAL_WARNING =
  'The start date must be before the end date';

export const fixIconMap = {
  gps: 'GPS_ICONS',
  wifi: 'WIFI_ICONS',
  cell: 'CELL_ICONS',
  ble: 'BLE_ICONS',
};

export const statusColorMap = {
  active: 'green',
  paid: 'green',
  enabled: 'green',
  succeeded: 'green',
  connected: 'green',
  completed: 'green',
  on: 'green',
  pending: 'blue',
  'pending-renewal': 'yellow',
  cart: 'yellow',
  cancelled: 'red',
  blocked: 'red',
  archived: 'red',
  suspended: 'red',
  disabled: 'red',
  rejected: 'red',
  inactive: 'red',
  off: 'red',
  inventory: 'gray',
  draft: 'gray',
  reserved: 'porto',
  published: 'porto',
  'pending-approval': 'porto',
  'pending-cancellation': 'yellow',
  'pending-shipment': 'blue',
  shipped: 'blue',
  'auto-approved': 'blue',
};

export const PAYABLE_ORDER_STATUSES = [
  'published',
  'pending-approval',
  'auto-approved',
];

export const AUTO_REFRESH_MS = 30000;

export const shipmentStatusColorMap = {
  'In Progress': 'bg-blue-500',
  Completed: 'bg-green-500',
  'Not Started': 'bg-gray-700',
  'Running Late': 'bg-red-500',
  'Completed Late': 'bg-red-500',
};
export const shipmentStatusStrokeMap = {
  'In Progress': 'stroke-blue-500',
  Completed: 'stroke-green-500',
  'Not Started': 'stroke-gray-700',
  'Running Late': 'stroke-red-500',
  'Completed Late': 'stroke-red-500',
};
export const tracksLegacyColors = {
  purple: '#706fd3',
  darkPurple: '#40407a',
  darkerPurple: '#474787',
  red: '#ff5252',
  darkerRed: '#b33939',
  orange: '#ff793f',
  darkOrange: '#cd6133',
  blue: '#34ace0',
  darkBlue: '#227093',
  lightOrange: '#ffb142',
  green: '#33d9b2',
  darkGreen: '#218c74',
};
export const fixColorMap = {
  cell: '#00f',
  wifi: '#0be',
  gps: '#1f6f88',
  ble: '#5e7bde',
};

export const breakpoints = {
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
};

export const mapCenter = [37.0902, -95.7129];

export const PLAN_ID_MONTH = 1;
export const PLAN_ID_YEAR = 2;

export const PERIOD_MONTH = 'month';
export const PERIOD_YEAR = 'year';

export const planPeriodMap = {
  [PLAN_ID_MONTH]: PERIOD_MONTH,
  [PLAN_ID_YEAR]: PERIOD_YEAR,
};

const PAYMENT_TERM_ON_RECEIPT = 'on-receipt';
const PAYMENT_TERM_NET_15 = 'net-15';
const PAYMENT_TERM_NET_30 = 'net-30';
const PAYMENT_TERM_NET_45 = 'net-45';
const PAYMENT_TERM_NET_60 = 'net-60';
const PAYMENT_TERM_NET_75 = 'net-75';
export const paymentTermMap = {
  [PAYMENT_TERM_ON_RECEIPT]: 'Due on receipt',
  [PAYMENT_TERM_NET_15]: 'Net 15',
  [PAYMENT_TERM_NET_30]: 'Net 30',
  [PAYMENT_TERM_NET_45]: 'Net 45',
  [PAYMENT_TERM_NET_60]: 'Net 60',
  [PAYMENT_TERM_NET_75]: 'Net 75',
};

export const BILLING_TURN_UPFRONT = 'up-front';
export const BILLING_TURN_DEVICE_UPFRONT = 'device-up-front';
export const BILLING_TURN_MONTHLY = 'monthly';
export const BILLING_TURN_DEVICE_UPFRONT_SUBSCRIPTION_ANNUALLY = 'device-up-front-annually';
export const billingTermMap = {
  [BILLING_TURN_UPFRONT]: 'All up-front',
  [BILLING_TURN_DEVICE_UPFRONT]: 'Software billed monthly, device up-front',
  [BILLING_TURN_MONTHLY]: 'Device & software billed monthly',
  [BILLING_TURN_DEVICE_UPFRONT_SUBSCRIPTION_ANNUALLY]: 'Devices Up Front - Subs Annually',
};

export const ROLE = {
  admin: 'admin',
  all: 'all',
  devices: 'devices',
};

export const SHIPMENT_CATEGORY = {
  general: 'general',
  archived: 'archived',
};

export const GEOFENCE_CATEGORY = {
  geofences: 'geofences',
  bleAnchors: 'bleAnchors',
};
export const DEVICE_CATEGORY = {
  allTrackers: 'allTrackers',
  groups: 'groups',
};

export const BATTERY_THRESHOLD = {
  low: 20,
  medium: 40,
};
